import { ReactNode } from 'react';

import { StyledListItem } from './styles';

interface IListItemProps {
  children: ReactNode;
}

const ListItem = ({ children }: IListItemProps) => {
  return <StyledListItem>{children}</StyledListItem>;
};

export default ListItem;
