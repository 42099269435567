import styled from '@emotion/styled';

const HorizontalRule = styled.hr`
  border: 3px solid ${({ theme }) => theme.colors.primary.orange};
  box-shadow: ${({ theme }) => theme.boxShadow};
  margin-top: 6px;
  margin-bottom: 20px;
`;

export default HorizontalRule;
