import { IAvatarProps } from 'components/Avatar';
import DatoStructuredText from 'components/DatoStructuredText';

import * as S from './styles';

interface IBackquoteProps {
  name: string;
  avatar: IAvatarProps;
  content: string;
}

const Backquote = ({ name, avatar, content }: IBackquoteProps) => {
  return (
    <S.Backquote>
      <S.Row>
        {avatar && <S.Avatar src={avatar.url} width={200} height={200} />}
        <S.QuoteContainer>
          <DatoStructuredText data={content} />
        </S.QuoteContainer>
        <S.QuoteImage src='/quote.png' width={80} height={80} />
      </S.Row>
      {name && <p>- {name}</p>}
    </S.Backquote>
  );
};

export default Backquote;
