import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { request as graphqlRequest, RequestDocument, Variables } from 'graphql-request';

export function datoRequest<TDocument = any>(
  document: RequestDocument | TypedDocumentNode<TDocument, Variables>,
  variables?: Variables,
  loadDraft = false
) {
  return graphqlRequest<TDocument, Variables>('https://graphql.datocms.com/', document, variables, {
    Authorization: `Bearer ${process.env.DATOCMS_API_TOKEN}`,
    ...(loadDraft && { 'X-Include-Drafts': 'true' })
  });
}
