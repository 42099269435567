import styled from '@emotion/styled';

import Link from 'components/Link';

export const Button = styled.button`
  overflow-wrap: break-word;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
  font-size: 0.9rem;
  line-height: 1;
  cursor: pointer;
  font-weight: 800;
  background: ${({ theme }) => theme.colors.secondary.white};
  text-transform: uppercase;
  border: 3px solid ${({ theme }) => theme.colors.primary.orange};
  color: ${({ theme }) => theme.colors.primary.orange};
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.5s;
  &::before {
    content: '';
    position: absolute;
    left: 15px;
    bottom: -3px;
    height: 4px;
    width: 10px;
    background: ${({ theme }) => theme.colors.secondary.white};
  }
  &::after {
    content: '';
    position: absolute;
    right: 15px;
    top: -3px;
    height: 4px;
    width: 10px;
    background: ${({ theme }) => theme.colors.secondary.white};
  }
  ${({ theme }) => theme.breakpoints.xl} {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary.white};
      background-color: ${({ theme }) => theme.colors.primary.orange};
      &:after,
      :before {
        display: none;
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  overflow-wrap: break-word;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
  font-size: 0.9rem;
  line-height: 1;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  border: 3px solid ${({ theme }) => theme.colors.primary.orange};
  color: ${({ theme }) => theme.colors.primary.orange};
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.5s;
  &::before {
    content: '';
    position: absolute;
    left: 15px;
    bottom: -3px;
    height: 4px;
    width: 10px;
    background: ${({ theme }) => theme.colors.secondary.white};
  }
  &::after {
    content: '';
    position: absolute;
    right: 15px;
    top: -3px;
    height: 4px;
    width: 10px;
    background: ${({ theme }) => theme.colors.secondary.white};
  }
  ${({ theme }) => theme.breakpoints.xl} {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary.white};
      background-color: ${({ theme }) => theme.colors.primary.orange};
      &:after,
      :before {
        display: none;
      }
    }
  }
`;
