import styled from '@emotion/styled';

import Image from 'components/Image';

export const Backquote = styled.blockquote`
  margin: 0;
  padding: 1rem;
  border: 1rem solid ${({ theme }) => theme.colors.secondary.lightGray};
`;

export const Row = styled.div`
  display: flex;
  ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`;
export const Avatar = styled(Image)`
  ${({ theme }) => theme.breakpoints.md} {
    width: 100px;
    height: 100px;
  }
  ${({ theme }) => theme.breakpoints.sm} {
    width: 100%;
    height: auto;
  }
`;

export const QuoteContainer = styled.div`
  padding-left: 1rem;
  ${({ theme }) => theme.breakpoints.sm} {
    padding-left: 0rem;
  }
`;

export const QuoteImage = styled(Image)`
  margin-left: 2rem;
  ${({ theme }) => theme.breakpoints.lg} {
    margin-top: -45px;
    margin-left: -40px;
    width: 60px;
    height: 60px;
  }
  ${({ theme }) => theme.breakpoints.sm} {
    display: none;
  }
`;
