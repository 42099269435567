import Avatar from 'components/Avatar';
import Typography from 'components/Typography';

import { theme } from 'theme';

import * as S from './styles';

interface IReviewerProps {
  name?: string;
  workPosition?: string;
  avatarUrl?: string;
}

const Reviewer = ({ name, workPosition, avatarUrl }: IReviewerProps) => {
  return (
    <S.Reviewer>
      {avatarUrl && (
        <div>
          <Avatar url={avatarUrl} />
        </div>
      )}
      <div>
        <div>
          <Typography.span fontSize='1.8rem' fontWeight={600} fontFamily={theme.fonts.montserrat}>
            {name}
          </Typography.span>
        </div>
        <div>
          <Typography.span fontSize='1.37rem'>{workPosition}</Typography.span>
        </div>
      </div>
    </S.Reviewer>
  );
};

export default Reviewer;
