import { HTMLAttributeAnchorTarget, ReactNode } from 'react';

import * as S from './styles';

interface IButton {
  children: ReactNode;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
  rel?: string;
  onClick?: () => void;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button = ({ children, href, target, className, rel, onClick, id, type }: IButton) => {
  if (href) {
    return (
      <S.StyledLink href={href} className={className} target={target} rel={rel} id={id}>
        {children}
      </S.StyledLink>
    );
  } else if (onClick) {
    return (
      <S.Button className={className} onClick={onClick} id={id}>
        {children}
      </S.Button>
    );
  } else {
    return (
      <S.Button className={className} id={id} type={type}>
        {children}
      </S.Button>
    );
  }
};

export default Button;
