import { ReactNode } from 'react';

interface IListProps {
  children: ReactNode;
  ordered: boolean;
}

const List = ({ children, ordered }: IListProps) => {
  if (ordered) {
    return <ol>{children}</ol>;
  } else {
    return <ul>{children}</ul>;
  }
};

export default List;
