import * as S from './styles';

export interface IAvatarProps {
  url: string;
}

export const Avatar = ({ url }: IAvatarProps) => {
  return <S.StyledAvatar src={url} width={100} height={100} />;
};

export default Avatar;
