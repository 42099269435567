import styled from '@emotion/styled';
import _ from 'lodash';

import Image from 'components/Image';

import { renderWidth } from 'lib/utils';

import { IGrid, IGridElementTextContent, IGridItem, IImageWrapper } from '.';

export const StyledGrid = styled.div<IGrid>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;

  gap: ${(props) => `${props.horizontalSpacing}%`};
  margin-bottom: ${(props) => `-${props.verticalSpacing}%`};
`;

export const ImageWrapper = styled.div<IImageWrapper>`
  margin-top: ${(props) => (_.isNull(props.topMargin) ? 'initial' : `${props.topMargin}px`)};
  margin-bottom: ${(props) =>
    _.isNull(props.bottomMargin) ? 'initial' : `${props.bottomMargin}px`};
  margin-left: ${(props) => (_.isNull(props.leftMargin) ? 'initial' : `${props.leftMargin}px`)};
  margin-right: ${(props) => (_.isNull(props.rightMargin) ? 'initial' : `${props.rightMargin}px`)};
`;
export const StyledImage = styled(Image)`
  object-fit: contain;
  height: 100%;
  width: 100%;

  max-height: ${(props) => `${props.height}px`};
`;

export const Content = styled.div<IGridElementTextContent>`
  margin-top: ${(props) =>
    _.isNull(props.contentMarginTop) ? 'initial' : `${props.contentMarginTop}px`};
  margin-bottom: ${(props) =>
    _.isNull(props.contentMarginBottom) ? 'initial' : `${props.contentMarginBottom}px`};
  margin-left: ${(props) =>
    _.isNull(props.contentMarginLeft) ? 'initial' : `${props.contentMarginLeft}px`};
  margin-right: ${(props) =>
    _.isNull(props.contentMarginRight) ? 'initial' : `${props.contentMarginRight}px`};
  padding: 3% 5%;
  display: flex;
  height: 100%;
  flex-direction: column;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: ${(props) => `${props.headingFormatting}`};
  }
  p {
    text-align: ${(props) => `${props.paragraphFormatting}`};
  }
  hr {
    width: 100%;
  }
  a {
    inline-size: fit-content;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  hr,
  a {
    margin: 0;
    margin-top: 10px;
  }
`;

export const GridItem = styled.div<IGridItem>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    !_.isNil(props.caseStudy) || !_.isNil(props.testimonial) ? 'space-between' : 'center'};

  padding: ${(props) => (!_.isNil(props.caseStudy) || !_.isNil(props.testimonial) ? '16px' : '')};
  border: ${(props) => (props.hasBorder ? '2px solid #fd7500;' : 'none')};
  box-shadow: ${(props) => (props.hasBorderShadow ? ({ theme }) => theme.boxShadow : 'none')};
  margin-bottom: ${(props) => `${props.verticalSpacing}%`};

  ${({ theme }) => theme.breakpoints.xl} {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: ${(props) =>
      renderWidth({
        spacing: props.horizontalSpacing,
        xs: props.xs,
        sm: props.sm,
        md: props.md,
        lg: props.lg,
        xl: props.xl
      })};
  }

  ${({ theme }) => theme.breakpoints.lg} {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: ${(props) =>
      renderWidth({
        spacing: props.horizontalSpacing,
        xs: props.xs,
        sm: props.sm,
        md: props.md,
        lg: props.lg
      })};
  }

  ${({ theme }) => theme.breakpoints.md} {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: ${(props) =>
      renderWidth({
        spacing: props.horizontalSpacing,
        xs: props.xs,
        sm: props.sm,
        md: props.md
      })};
  }
  ${({ theme }) => theme.breakpoints.sm} {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: ${(props) =>
      renderWidth({
        spacing: props.horizontalSpacing,
        xs: props.xs,
        sm: props.sm
      })};
  }

  ${({ theme }) => theme.breakpoints.xs} {
    flex-grow: 1;
    flex-shrink: 1;

    flex-basis: ${(props) => renderWidth({ spacing: props.horizontalSpacing, xs: props.xs })};
  }
`;

export const CaseStudyText = styled.div`
  display: flex;
  gap: 1.2rem;
`;

export const QuoteImage = styled(Image)`
  ${({ theme }) => theme.breakpoints.md} {
    width: 70px;
    height: auto;
  }
`;
