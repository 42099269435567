import styled from '@emotion/styled';

import Image from 'components/Image';

export const StyledAvatar = styled(Image)`
  border-radius: 50%;
  border: 3px solid ${({ theme }) => theme.colors.primary.orange};
  object-position: top;
  object-fit: cover;
`;
