import Image from 'components/Image';

import * as S from './styles';

interface ILogosGropProps {
  logos: {
    url: string;
    alt: string;
    responsiveImage: {
      aspectRatio: number;
    };
  }[];
}

const LogosGroup = ({ logos }: ILogosGropProps) => {
  return (
    <S.Wrapper>
      {logos.map((logo, index) => (
        <div key={index}>
          <Image
            src={logo.url}
            alt={logo.alt}
            height={104}
            width={104 * logo.responsiveImage.aspectRatio}
            style={{ margin: 'auto', maxWidth: '100%', height: 'auto' }}
          />
        </div>
      ))}
    </S.Wrapper>
  );
};

export default LogosGroup;
