import _ from 'lodash';

import DatoStructuredText from 'components/DatoStructuredText';
import Reviewer from 'components/Reviewer';
import Typography from 'components/Typography';

import * as S from './styles';

export interface IGrid {
  items: IGridItem[];
  verticalSpacing: number;
  horizontalSpacing: number;
}

export interface IImageWrapper {
  topMargin?: number;
  bottomMargin?: number;
  leftMargin?: number;
  rightMargin?: number;
}

interface ICaseStudyAndTestimonal {
  reviewerName: string;
  companyPosition: string;
  review: string;
  reviewerAvatar: { url: string };
}

export interface IGridItem {
  verticalSpacing: number;
  horizontalSpacing: number;
  headingFormatting: string;
  paragraphFormatting: string;
  imageHeight: number;
  imageMarginTop?: number;
  imageMarginBottom?: number;
  imageMarginLeft?: number;
  imageMarginRight?: number;
  contentMarginTop?: number;
  contentMarginBottom?: number;
  contentMarginLeft?: number;
  contentMarginRight?: number;
  caseStudy?: ICaseStudyAndTestimonal;
  testimonial?: ICaseStudyAndTestimonal;
  hasBorder?: boolean;
  hasBorderShadow?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  content?: any;
  contentImage?: any;
}

export interface IGridElementTextContent {
  headingFormatting: string;
  paragraphFormatting: string;
  contentMarginTop?: number;
  contentMarginBottom?: number;
  contentMarginLeft?: number;
  contentMarginRight?: number;
}

const Grid = ({ items, verticalSpacing, horizontalSpacing }: IGrid) => {
  return (
    <S.StyledGrid
      items={items}
      verticalSpacing={verticalSpacing}
      horizontalSpacing={horizontalSpacing}
    >
      {items.map((item, index) => {
        const showTestimonial = !_.isNil(item.testimonial);
        const showCaseStudy = !showTestimonial && !_.isNil(item.caseStudy);
        const showTextContent = !showCaseStudy && !showTestimonial;
        return (
          <S.GridItem
            key={index}
            {...item}
            verticalSpacing={verticalSpacing}
            horizontalSpacing={horizontalSpacing}
          >
            {!_.isNil(item.contentImage) && (
              <S.ImageWrapper
                topMargin={item.imageMarginTop}
                bottomMargin={item.imageMarginBottom}
                leftMargin={item.imageMarginLeft}
                rightMargin={item.imageMarginRight}
              >
                <S.StyledImage
                  src={item.contentImage.url}
                  height={item.imageHeight}
                  width={item.imageHeight * item.contentImage.responsiveImage.aspectRatio}
                  key={item.contentImage.url}
                />
              </S.ImageWrapper>
            )}
            {showTextContent && (
              <S.Content
                headingFormatting={item.headingFormatting}
                paragraphFormatting={item.paragraphFormatting}
                contentMarginTop={item.contentMarginTop}
                contentMarginBottom={item.contentMarginBottom}
                contentMarginLeft={item.contentMarginLeft}
                contentMarginRight={item.contentMarginRight}
              >
                <DatoStructuredText data={item.content} />
              </S.Content>
            )}

            {showCaseStudy && (
              <>
                <S.CaseStudyText>
                  <div>
                    <S.QuoteImage src='/quote.png' width={100} height={100} />
                  </div>
                  <Typography.div>{item.caseStudy?.review}</Typography.div>
                </S.CaseStudyText>
                <Reviewer
                  name={item.caseStudy?.reviewerName}
                  workPosition={item.caseStudy?.companyPosition}
                  avatarUrl={item.caseStudy?.reviewerAvatar?.url}
                />
              </>
            )}
            {showTestimonial && (
              <>
                <S.CaseStudyText>
                  <div>
                    <S.QuoteImage src='/quote.png' width={100} height={100} />
                  </div>
                  <Typography.div>{item.testimonial?.review}</Typography.div>
                </S.CaseStudyText>
                <Reviewer
                  name={item.testimonial?.reviewerName}
                  workPosition={item.testimonial?.companyPosition}
                  avatarUrl={item.testimonial?.reviewerAvatar?.url}
                />
              </>
            )}
          </S.GridItem>
        );
      })}
    </S.StyledGrid>
  );
};
export default Grid;
