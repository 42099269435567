import { renderMarkRule, renderNodeRule, StructuredText } from 'react-datocms/structured-text';

import {
  isHeading,
  isList,
  isListItem,
  isParagraph,
  isThematicBreak
} from 'datocms-structured-text-utils';

import Backquote from 'components/Backquote';
import Grid from 'components/Grid';
import HorizontalRule from 'components/HirozontalRule';
import Image from 'components/Image';
import List from 'components/List';
import ListItem from 'components/ListItem';
import LogosGroup from 'components/LogosGroup';
import Reviewer from 'components/Reviewer';
import Typography from 'components/Typography';

import * as S from './styles';

const renderBlock = ({ record }: { record: any }) => {
  switch (record.__typename) {
    case 'CtaButtonRecord':
      return (
        <S.DatoButton
          href={record.buttonLink}
          target={record.newTab && '_blank'}
          id={record.trackingId}
        >
          {record.buttonText}
        </S.DatoButton>
      );
    case 'LogosGroupRecord':
      return <LogosGroup {...record} />;
    case 'ReviewerRecord':
      return <Reviewer {...record} />;
    case 'ImageRecord':
      return (
        <Image
          src={record.image.url}
          width={record.image.width}
          height={record.image.height}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      );
    case 'QuoteRecord':
      return <Backquote name={record.name} content={record.content} avatar={record.avatar} />;
    case 'GridRecord':
      return (
        <Grid
          horizontalSpacing={record.horizontalSpacing}
          verticalSpacing={record.verticalSpacing}
          items={record.gridContent}
        />
      );
    default:
      return null;
  }
};

const customNodeRules = [
  renderNodeRule(isHeading, ({ node, children, key }) => {
    switch (node.level) {
      case 1:
        return <Typography.h1 key={key}>{children}</Typography.h1>;
      case 2:
        return <Typography.h2 key={key}>{children}</Typography.h2>;
      case 3:
        return <Typography.h3 key={key}>{children}</Typography.h3>;
      case 4:
        return <Typography.h4 key={key}>{children}</Typography.h4>;
      case 5:
        return <Typography.h5 key={key}>{children}</Typography.h5>;
      case 6:
        return <Typography.h5 key={key}>{children}</Typography.h5>;
      default:
        null;
    }
  }),
  renderNodeRule(isParagraph, ({ node, children, key }) => {
    if (node.style === 'subheading') {
      return <Typography.subheading key={key}>{children}</Typography.subheading>;
    }
    return <Typography.body1 key={key}>{children}</Typography.body1>;
  }),
  renderNodeRule(isThematicBreak, ({ key }) => {
    return <HorizontalRule key={key} />;
  }),
  renderNodeRule(isList, ({ node, children, key }) => {
    return (
      <List key={key} ordered={node.style === 'numbered'}>
        {children}
      </List>
    );
  }),
  renderNodeRule(isListItem, ({ children, key }) => {
    return <ListItem key={key}>{children}</ListItem>;
  })
];

const customMarkRules = [
  renderMarkRule(
    (node) => node === 'strong',
    ({ children }) => {
      return <Typography.strong>{children}</Typography.strong>;
    }
  ),
  renderMarkRule(
    (node) => node === 'highlight',
    ({ children }) => {
      return <Typography.mark>{children}</Typography.mark>;
    }
  )
];

const DatoStructuredText = ({ data }: { data: any }) => {
  return (
    <StructuredText
      data={data}
      renderBlock={renderBlock}
      customNodeRules={customNodeRules}
      customMarkRules={customMarkRules}
    />
  );
};
export default DatoStructuredText;
