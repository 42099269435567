import styled from '@emotion/styled';

const h1 = styled.h1`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  margin: 0 0 0.5rem 0;
  font-size: 2.4rem;
  font-weight: 600;
`;

const h2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  margin: 0 0 0.5rem 0;
  font-size: 2.1rem;
  font-weight: 600;
`;

const h3 = styled.h3`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  margin: 0 0 0.5rem 0;
  font-size: 1.8rem;
  font-weight: 500;
`;

const h4 = styled.h4`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 400;
`;

const h5 = styled.h5`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
  font-weight: 300;
`;

const body1 = styled.p`
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
`;

const subheading = styled.p`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-size: 1.5rem;
  font-weight: 400;
`;

interface ICustomProps {
  fontSize?: string;
  fontWeight?: number;
  fontFamily?: string;
}

const span = styled.span<ICustomProps>`
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
`;

const div = styled.span<ICustomProps>`
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
`;

const strong = styled.strong<ICustomProps>`
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
  font-weight: ${({ fontWeight }) => fontWeight || '800'};
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
`;

const mark = styled.mark<ICustomProps>`
  font-family: ${({ theme }) => theme.fonts.nunitoSans};
  font-weight: ${({ fontWeight }) => fontWeight || '800'};
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  background-color: ${({ theme }) => theme.colors.secondary.highlightText};
`;

const Typography = {
  h1,
  h2,
  h3,
  h4,
  h5,
  body1,
  span,
  div,
  subheading,
  strong,
  mark
};

export default Typography;
