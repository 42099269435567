import { PostOrPage } from '@tryghost/content-api';
import { AllNavitemsDocument, AllNavitemsQuery } from 'graphql/generated';
import isNil from 'lodash/isNil';

import { datoRequest } from './datoSDK';

type Author = {
  ghostId?: string;
};

export const getBlogPostAuthors = <T extends Author>(
  blogPost: PostOrPage,
  allTeamMembers: T[]
): T[] => {
  return (
    allTeamMembers.filter((author) =>
      blogPost.authors?.find((blogPostAuthor) => blogPostAuthor.id === author.ghostId)
    ) || []
  );
};

export const openInNewTab = (url: string) => window.open(url, '_blank');

export const uniqueBy = (array: any[], key: string) => [
  ...new Map(array.map((item) => [item[key], item])).values()
];

export const chunk = (array: any[], size: number) =>
  Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
    array.slice(i * size, i * size + size)
  );

export const generateAllUrls = (allContentPages: any[]) => {
  const result: string[] = [];

  const traverse = (node: any) => {
    if (node === null) {
      return;
    }

    //const newPath = currentPath ? `${currentPath}/${node.slug}` : node.slug;
    const newPath = node.slug;

    if (node.children && node.children.length > 0) {
      node.children.forEach((child: any) => {
        traverse(child);
      });
    } else {
      result.push(newPath);
    }
  };

  allContentPages.forEach((node) => {
    traverse(node);
  });

  return result;
};

export const getNavItems = async () => {
  try {
    return await datoRequest<AllNavitemsQuery>(AllNavitemsDocument);
  } catch (error) {
    console.error('Error fetching navigation items:', error);
  }
};

const calculateWidth = ({ value, spacing }: { value: number; spacing: number }) =>
  (value / 12) * 100 - spacing;

export const renderWidth = ({
  spacing,
  xs,
  sm,
  md,
  lg,
  xl
}: {
  spacing: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}) => {
  const filteredItemSizes = [xl, lg, md, sm, xs].filter((size) => !isNil(size)) as number[];

  if (filteredItemSizes.length > 0) {
    const value = filteredItemSizes[0];
    return `${calculateWidth({ value, spacing })}%`;
  }
  return 'auto;';
};
